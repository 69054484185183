<template>
  <div class="container-height">
    <!-- Nav Header -->
    <div class="space-y-2 p-6 border-b border-neutral-50">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col gap-y-2">
          <div class="flex flex-row gap-x-3 items-center">
            <ic-arrow
              v-if="selectedMonitoringGroup"
              class="cursor-pointer hover:text-bb-brand-purple duration-300 ease-linear"
              @click="backToCMS"
            />
            <h3 class="h3">All Monitoring Groups</h3>
          </div>
          <p class="text-sm font-normal">Access and edit all your monitoring groups at any time</p>
        </div>
        <merge-button-round
          button-type="brand-purple"
          class="mt-5 mr-5"
          @click="createNewGroup"
          >New Group
          <template #left-icon>
            <ic-plus
              :size="16"
              class="my-auto"
            />
          </template>
        </merge-button-round>
      </div>
    </div>
    <!-- Template -->
    <all-monitoring-groups
      :monitoring-groups="computedMonitoringGroups"
      :selected-monitoring-group="computedSelectedMonitoringGroup"
      @show-selected-monitoring-settings="showSelectedMonitoringSettings"
      @select-monitoring-group="selectMonitoringGroup"
    />
  </div>
</template>

<script>
import AllMonitoringGroups from './AllMonitoringGroups.vue'
import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AllGroups',
  components: {
    AllMonitoringGroups,
    IcArrow,
  },
  data() {
    return {
      hasMonitoringJob: true,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoringV2', ['monitoringGroups', 'selectedMonitoringGroup', 'campaignsWithCms']),
    computedMonitoringGroups() {
      return this.monitoringGroups.map(group => {
        return {
          ...group,
          campaignName: this.campaignsWithCms[group?.googleCampaignId]?.name ?? null,
        }
      })
    },
    computedSelectedMonitoringGroup() {
      return this.computedMonitoringGroups.find(group => group.id === this.selectedMonitoringGroup?.id)
    },
  },
  methods: {
    ...mapActions('competitorMonitoringV2', ['loadSelectedMonitoringGroup', 'clear', 'setVisibleModal']),
    createNewGroup() {
      this.$router.push({ name: 'onboarding-v2' })
    },
    async showSelectedMonitoringSettings(monitoringGroup) {
      await this.loadSelectedMonitoringGroup({ siteId: this.selectedSite.value, monitoringGroupId: monitoringGroup.id })
      this.setVisibleModal({ type: 'selected-monitoring-settings', width: '800px', height: '720px' })
    },
    backToCMS() {
      if (!this.selectedMonitoringGroup) return
      this.$router.push({ name: 'competitor-monitoring-v2' })
    },
    async selectMonitoringGroup(monitoringGroup) {
      this.clear()
      await this.loadSelectedMonitoringGroup({ siteId: this.selectedSite.value, monitoringGroupId: monitoringGroup.id })
      this.backToCMS()
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 200px);
}
</style>
